import { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Form } from 'reactstrap'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import {
  FeatureFlagsSchema,
  FeatureFlagsSchemaEntry,
  FeatureFlagsSchemaObjectEntry,
  FeatureFlagsWithSchema,
  FeatureFlagValue,
  GenericFeatureFlags,
} from 'domains/featureFlags'
import { StandardErrorResponse } from 'types/APIResponses'
import { getValueByPath } from 'utils/getValueByPath'

import { FeatureFlagRootObject } from './FeatureFlagTree'
import { featureFlagsDiff } from './utils/featureFlagsDiff'

interface PermissionsControlProps {
  username: string
}

export const PermissionsControl = ({ username }: PermissionsControlProps) => {
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [dbFFs, setDbFFs] = useState<GenericFeatureFlags>()
  const [ffsSchema, setFfsSchema] = useState<FeatureFlagsSchema>()
  const form = useForm<GenericFeatureFlags>({
    mode: 'onSubmit',
  })

  const fillFormWithValues = (
    path: string[],
    ffSchemaNode: FeatureFlagsSchemaObjectEntry | FeatureFlagsSchemaEntry,
    valueSelector: (path: string) => GenericFeatureFlags | FeatureFlagValue | FeatureFlagValue[],
  ) => {
    if (ffSchemaNode.type === 'object') {
      for (const property of Object.entries(ffSchemaNode.properties)) {
        fillFormWithValues([...path, property[0]], property[1], valueSelector)
      }

      return
    }

    if (ffSchemaNode.readOnly) {
      return
    }

    const fullPath = path.join('.')
    const valueToSet = valueSelector(fullPath) ?? ffSchemaNode.default

    form.register(fullPath)

    if (valueToSet == undefined) {
      return
    }

    form.setValue(fullPath, valueToSet)
  }

  const applyServerData = (serverData: FeatureFlagsWithSchema) => {
    fillFormWithValues(
      [],
      serverData.schema,
      (path) => getValueByPath(serverData.override, path) ?? getValueByPath(serverData.data, path),
    )

    setFfsSchema(serverData.schema)
    setDbFFs(serverData.data)
  }

  useEffect(() => {
    API.getUsersFeatureFlagsWithSchema(username)
      .then(applyServerData)
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }, [username, requestStatusRef, form, setDbFFs, setFfsSchema])

  const updatePermissions = () => {
    if (!dbFFs) {
      return
    }

    const overrideValues = form.watch()
    const ffDiff = featureFlagsDiff(dbFFs, overrideValues)

    if (!ffDiff) {
      return
    }

    API.updateFeatureFlagsOverride(username, ffDiff)
      .then((response) => {
        applyServerData(response)
        requestStatusRef.current?.showAlert('Permissions updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const restoreDefaults = () => {
    if (!ffsSchema || !dbFFs) {
      return
    }

    form.reset()
    fillFormWithValues([], ffsSchema, (path) => getValueByPath(dbFFs, path))
  }

  return (
    <FormProvider {...form}>
      {ffsSchema && (
        <Form>
          <FeatureFlagRootObject ffSchemaObject={ffsSchema} dbFFObject={dbFFs ?? {}} />
        </Form>
      )}

      <div className="d-flex justify-content-end mt-3" style={{ gap: '1rem' }}>
        <button disabled={!ffsSchema} onClick={restoreDefaults}>
          Restore defaults
        </button>
        <button disabled={!ffsSchema} onClick={updatePermissions}>
          Update permissions
        </button>
      </div>
    </FormProvider>
  )
}
